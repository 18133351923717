import React from "react";
import {
  Container,
  Button,
  Row,
  Col,
  Table,
} from "reactstrap";
import "./agenda.css";

function Agenda() {
  const dataAgendaNew = [
    {
      time: "08:00 - 09:00 AM",
      timeSub: "",
      border: "1px solid #f5f5f5",
      bg: "#f2f2f2",
      color: "",
      content: "Registration & Welcome Coffee",
    },
    {
      time: "09:00 - 09:05 AM",
      timeSub: "",
      border: "1px solid #f5f5f5",
      bg: "",
      color: "",
      content: "Welcome Note by Khaleej Times",
    },
    {
      time: "09:05 – 09:20 AM",
      timeSub: "(15 min)",
      bg: "",
      color: "",
      border: "1px solid #f5f5f5",
      content:
        "Opening Keynote Address | Finance and Banking | The Future of Finance in the Kingdom",
      bullets: [
        "Insights into the Kingdom's transformative digital banking landscape and FinTech integration trends.",
        "Assessing the impact of evolving regulations on FinTech and banking sectors.",
        "Exploring the interplay between sustainable banking practices and FinTech innovations.",
      ],
    },
    {
      time: "09:20 – 10:00 AM",
      timeSub: "(40 min)",
      bg: "",
      color: "",
      border: "1px solid #f5f5f5",
      content:
        "Panel Discussion | Payments & Fintech | Evolving Payments Landscape in the Kingdom",
      bullets: [
        "Exploration of the evolving payment ecosystem underpinned by Vision 2030",
        "Analysis of innovations driving the shift towards a cashless society in the Kingdom, underpinned by digital technologies and fintech advancements.",
        "Addressing key challenges such as regulatory compliance, cybersecurity, and integrating new technologies into traditional banking systems.",
      ],
    },
    {
      time: "10:00 - 10:40 AM",
      timeSub: "(40 min)",
      border: "1px solid #f5f5f5",
      bg: "",
      color: "",
      content:
        "Panel Discussion | Credit Cards & Loyalty Programs | Revolutionizing the Credit Card Insights into Cards, Rewards, and Loyalty Programs",
      bullets: [
        "Implications of SAR 27 billion/ 17% year-on-year (YoY) in 2023 in Saudi credit cards",
        "Digital Shifts and Cashless Ambitions in Saudi Arabia",
        "Innovative strategies in loyalty programs, integration of technology",
      ],
    },
    {
      time: "10:40 – 11:00 AM",
      timeSub: "(20 min)",
      border: "1px solid #f5f5f5",
      bg: "",
      color: "",
      content: "Keynote | BY WNS - Vuram Hyper Automation + AI",
    },
    {
      time: "11:00 – 11:15 AM",
      timeSub: "",
      border: "1px solid #f5f5f5",
      bg: "#f2f2f2",
      color: "",
      content: "Coffee and Networking Break",
    },
    {
      time: "11:15 – 11:55 AM",
      timeSub: "(40 min)",
      border: "1px solid #f5f5f5",
      bg: "",
      color: "",
      content:
        "Panel Discussion | Regulatory Compliance in Banking | Navigating Regulatory Challenges in Banking",
      bullets: [
        "Compliance frameworks: Implementing robust systems and procedures to adhere to regulatory requirements and standards.",
        "Risk management: Identifying, assessing, and mitigating risks associated with regulatory compliance, financial stability, and reputation.",
        "Regulatory updates: Staying informed about changes in regulations, guidelines, and best practices to ensure ongoing compliance and adaptability in a dynamic.",
      ],
    },
    {
      time: "11:55 – 12:10 PM",
      timeSub: "(15 min)",
      border: "1px solid #f5f5f5",
      bg: "",
      color: "",
      content:
        "Fire Side Chat | Sustainable Finance | Sustainability as a Core Financial Strategy",
      bullets: [
        "The integration of sustainable finance principles in banking operations.",
        "Saudi Arabia`s strides towards green finance and investments.",
        "The synergy between Islamic finance principles & global sustainability goals.",
      ],
    },
    {
      time: "12:10 – 12:50 PM",
      timeSub: "(40 min)",
      border: "1px solid #f5f5f5",
      bg: "",
      color: "",
      content:
        "Panel Discussion | Fintech & Digital Transformation | Driving Digital Banking Innovation Through AI-Led New Technologies Revolutionizing Saudi Banking",
      bullets: [
        "Innovating Financial Technologies and Automation.",
        "Global and Regional Digital Transformation Insights.",
        "Role of Fintechs in Driving Digital Transformation.",
        "Regulatory Impact and Customer Readiness.",
      ],
    },
    {
      time: "12:50 – 13:05 PM",
      timeSub: "",
      border: "1px solid #f5f5f5",
      bg: "#f2f2f2",
      color: "",
      content: "Prayer and Networking Break",
    },
    {
      time: "13:05 – 13:20 PM",
      timeSub: "(15 min)",
      border: "1px solid #f5f5f5",
      bg: "",
      color: "",
      content:
        "Keynote | Adopting Fintech | Harnessing Financial Technology to Modernize Wealth Management Strategies",
      bullets: [
        "Fintech technologies revolutionizing wealth management: Robo-Advisory to blockchain-based asset tracking.",
        "Integrating fintech solutions for personalized, data-driven investment guidance and portfolio optimization.",
        "Fintech's impact on improving financial inclusion and empowering underserved communities.",
      ],
    },
    {
      time: "13:20 – 14:00 PM",
      timeSub: "(40 min)",
      border: "1px solid #f5f5f5",
      bg: "",
      color: "",
      content:
        "Panel Discussion | Catalyzing Fintech Innovation | The Vital Role of Venture Capital:",
      bullets: [
        "Fueling Innovation and Scaling Operations",
        "Supporting Advanced Technologies and Financial Inclusion",
        "Facilitating Partnerships and Ensuring Ecosystem Resilience",
      ],
    },
    {
      time: "14:00 – 14:15 PM",
      timeSub: "(15 min)",
      border: "1px solid #f5f5f5",
      bg: "",
      color: "",
      content:
        "Fire Side Chat | Open Banking & Personalized Finance | Open Banking & the Future of Personalized Financial Services",
      bullets: [
        "Exploring the impact of open banking initiatives on delivering personalized financial services.",
        "Leveraging open APIs and data-driven insights to enhance customer experiences and offerings.",
        "Addressing regulatory considerations and data privacy concerns in the open banking ecosystem.",
      ],
    },
    {
      time: "14:15 – 14:30 PM",
      timeSub: "(15 min)",
      border: "1px solid #f5f5f5",
      bg: "",
      color: "",
      content:
        "keynote | FinTech Impact | Reshaping Traditional Finance with Tech-Driven Solutions",
      bullets: [
        "Traditional finance institutions are embracing fintech solutions for enhanced efficiency and competitiveness.",
        "Tech-driven innovations like AI, blockchain, and DeFi are reshaping banking interactions and services",
        "Fintech fostering a shift towards customer-centric finance, driving tailored solutions and improved experiences.",
      ],
    },
    // {
    //   time: "2:30 PM",
    //   timeSub: "",
    //   border: "1px solid #f5f5f5",
    //   bg: "#f2f2f2",
    //   color: "",
    //   content: "Closing Remarks",
    // },
    {
      time: "14:35 – 15:35 PM ",
      timeSub: "",
      border: "1px solid #f5f5f5",
      bg: "#c3e3ff",
      color: "",
      content: "Networking Lunch & End of the Conference",
    },
  ];
  const dataAgenda = [
    {
      time: "8:00 AM",
      timeSub: "",
      border: "1px solid #f5f5f5",
      bg: "#f2f2f2",
      color: "",
      content: "Registration & Welcome Coffee",
    },
    {
      time: "9:00 AM",
      timeSub: "",
      border: "1px solid #f5f5f5",
      bg: "",
      color: "",
      content: "Welcome Note by Khaleej Times",
    },
    {
      time: "9:05 AM",
      timeSub: "(15 min)",
      bg: "",
      color: "",
      border: "1px solid #f5f5f5",
      content:
        "Opening Keynote Address | Finance and Banking | The Future of Finance in the Kingdom",
      bullets: [
        "Insights into the Kingdom's transformative digital banking landscape and FinTech integration trends.",
        "Assessing the impact of evolving regulations on FinTech and banking sectors.",
        "Exploring the interplay between sustainable banking practices and FinTech innovations.",
      ],
    },
    {
      time: "9:20 AM",
      timeSub: "(40 min)",
      bg: "",
      color: "",
      border: "1px solid #f5f5f5",
      content:
        "Panel Discussion | Payments & Fintech | Evolving Payments Landscape in the Kingdom",
      bullets: [
        "Exploration of the evolving payment ecosystem underpinned by Vision 2030",
        "Analysis of innovations driving the shift towards a cashless society in the Kingdom, underpinned by digital technologies and fintech advancements.",
        "Addressing key challenges such as regulatory compliance, cybersecurity, and integrating new technologies into traditional banking systems.",
      ],
    },
    {
      time: "10:00 AM",
      timeSub: "(40 min)",
      border: "1px solid #f5f5f5",
      bg: "",
      color: "",
      content:
        "Panel Discussion | Credit Cards & Loyalty Programs | Revolutionizing the Credit Card Insights into Cards, Rewards, and Loyalty Programs",
      bullets: [
        "Implications of SAR 27 billion/ 17% year-on-year (YoY) in 2023 in Saudi credit cards",
        "Digital Shifts and Cashless Ambitions in Saudi Arabia",
        "Innovative strategies in loyalty programs, integration of technology",
      ],
    },
    {
      time: "10:40 AM",
      timeSub: "(20 min)",
      border: "1px solid #f5f5f5",
      bg: "",
      color: "",
      content: "Keynote | BY WNS - Vuram Hyper Automation + AI",
    },
    {
      time: "11:00 AM",
      timeSub: "",
      border: "1px solid #f5f5f5",
      bg: "#f2f2f2",
      color: "",
      content: "Coffee and Networking Break",
    },
    {
      time: "11:15 AM",
      timeSub: "(40 min)",
      border: "1px solid #f5f5f5",
      bg: "",
      color: "",
      content:
        "Panel Discussion | Regulatory Compliance in Banking | Navigating Regulatory Challenges in Banking",
      bullets: [
        "Compliance frameworks: Implementing robust systems and procedures to adhere to regulatory requirements and standards.",
        "Risk management: Identifying, assessing, and mitigating risks associated with regulatory compliance, financial stability, and reputation.",
        "Regulatory updates: Staying informed about changes in regulations, guidelines, and best practices to ensure ongoing compliance and adaptability in a dynamic.",
      ],
    },
    {
      time: "11:55 AM",
      timeSub: "(15 min)",
      border: "1px solid #f5f5f5",
      bg: "",
      color: "",
      content:
        "Fire Side Chat | Sustainable Finance | Sustainability as a Core Financial Strategy",
      bullets: [
        "The integration of sustainable finance principles in banking operations.",
        "Saudi Arabia`s strides towards green finance and investments.",
        "The synergy between Islamic finance principles & global sustainability goals.",
      ],
    },
    {
      time: "12:10 PM",
      timeSub: "(40 min)",
      border: "1px solid #f5f5f5",
      bg: "",
      color: "",
      content:
        "Panel Discussion | Fintech & Digital Transformation | Driving Digital Banking Innovation Through AI-Led New Technologies Revolutionizing Saudi Banking",
      bullets: [
        "Innovating Financial Technologies and Automation.",
        "Global and Regional Digital Transformation Insights.",
        "Role of Fintechs in Driving Digital Transformation.",
        "Regulatory Impact and Customer Readiness.",
      ],
    },
    {
      time: "12:50PM",
      timeSub: "",
      border: "1px solid #f5f5f5",
      bg: "#f2f2f2",
      color: "",
      content: "Prayer and Networking Break",
    },
    {
      time: "1:05 PM",
      timeSub: "(15 min)",
      border: "1px solid #f5f5f5",
      bg: "",
      color: "",
      content:
        "Keynote | Adopting Fintech | Harnessing Financial Technology to Modernize Wealth Management Strategies",
      bullets: [
        "Fintech technologies revolutionizing wealth management: Robo-Advisory to blockchain-based asset tracking.",
        "Integrating fintech solutions for personalized, data-driven investment guidance and portfolio optimization.",
        "Fintech's impact on improving financial inclusion and empowering underserved communities.",
      ],
    },
    {
      time: "1:20 PM",
      timeSub: "(40 min)",
      border: "1px solid #f5f5f5",
      bg: "",
      color: "",
      content:
        "Panel Discussion | Catalyzing Fintech Innovation | The Vital Role of Venture Capital:",
      bullets: [
        "Fueling Innovation and Scaling Operations",
        "Supporting Advanced Technologies and Financial Inclusion",
        "Facilitating Partnerships and Ensuring Ecosystem Resilience",
      ],
    },
    {
      time: "2:00 PM",
      timeSub: "(15 min)",
      border: "1px solid #f5f5f5",
      bg: "",
      color: "",
      content:
        "Fire Side Chat | Open Banking & Personalized Finance | Open Banking & the Future of Personalized Financial Services",
      bullets: [
        "Exploring the impact of open banking initiatives on delivering personalized financial services.",
        "Leveraging open APIs and data-driven insights to enhance customer experiences and offerings.",
        "Addressing regulatory considerations and data privacy concerns in the open banking ecosystem.",
      ],
    },
    {
      time: "2:15 PM",
      timeSub: "(15 min)",
      border: "1px solid #f5f5f5",
      bg: "",
      color: "",
      content:
        "keynote | FinTech Impact | Reshaping Traditional Finance with Tech-Driven Solutions",
      bullets: [
        "Traditional finance institutions are embracing fintech solutions for enhanced efficiency and competitiveness.",
        "Tech-driven innovations like AI, blockchain, and DeFi are reshaping banking interactions and services",
        "Fintech fostering a shift towards customer-centric finance, driving tailored solutions and improved experiences.",
      ],
    },
    {
      time: "2:30 PM",
      timeSub: "",
      border: "1px solid #f5f5f5",
      bg: "#f2f2f2",
      color: "",
      content: "Closing Remarks",
    },
    {
      time: "2:35 PM",
      timeSub: "",
      border: "1px solid #f5f5f5",
      bg: "#c3e3ff",
      color: "",
      content: "Networking Lunch & End of the Conference",
    },
  ];

  return (
    <>
      <div
        style={{
          backgroundColor: "transparent",
          display: "none",
          // backgroundImage: "linear-gradient(315deg, #ffffff 0%, #1b2845 74%)",

          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
        }}
        className="section pb-0"
        data-parallax={true}
      >
        <Container>
          <h1 className="header-text text-dark mb-4">EVENT AGENDA</h1>
          <div>
            {/* {console.log(dataAgendaNew)} */}
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th
                    className="text-center "
                    style={{ background: "black", color: "white" }}
                  >
                    Time
                  </th>
                  <th
                    className="text-left "
                    style={{ background: "black", color: "white" }}
                  >
                    Session
                  </th>
                </tr>
              </thead>
              <tbody>
                {dataAgendaNew.map((s, index) => (
                  <tr key={index}>
                    <td className="py-3 mb-1 text-right ">
                      <h5
                        className="text-center d-inline-block "
                        style={{ width: "180px" }}
                      >
                        {s.time}
                        {/* {s.timeSub && (
                          <small className="d-block">{s.timeSub}</small>
                        )} */}
                      </h5>
                    </td>
                    <td className="py-3 mb-1 text-left contentShow">
                      <h5>{s.content}</h5>
                      {/* {s.bullets && s.bullets.length > 0 && (
                        <ul>
                          {s.bullets.map((bullet, i) => (
                            <li key={i}>{bullet}</li>
                          ))}
                        </ul>
                      )} */}
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
            {/* {dataAgendaNew.map((s) => (
              <div
                style={{
                  background: s.bg,
                  color: s.color,
                  border: s.border,
                }}
                className="agendaList"
              >
                <Table striped bordered hover>
                  <thead>
                    <tr>
                      <th className="text-center">Time</th>
                      <th className="text-left">Content</th>
                    </tr>
                  </thead>

                  <tbody>
                    <thead>
                      <tr>
                        <Col
                          md={3}
                          className="py-3 mb-1 text-right timeShowCont"
                        >
                          <h3 className="text-center d-inline-block timeShow">
                            {s.time}
                            {s.timeSub && (
                              <small className="d-block">{s.timeSub}</small>
                            )}
                          </h3>
                        </Col>
                      </tr>
                    </thead>

                    <thead>
                      <tr>
                        <Col md={9} className="py-3 mb-1 text-left contentShow">
                          <h3>{s.content}</h3>
                          {s.bullets && s.bullets.length > 0 && (
                            <ul className="">
                              {s.bullets.map((s, i) => (
                                <li key={i}>{s}</li>
                              ))}
                            </ul>
                          )}
                        </Col>
                      </tr>
                    </thead>
                  </tbody>
                </Table>
              </div>
            ))} */}
          </div>
          <div className="d-none">
            {dataAgenda.map((s) => (
              <div
                style={{
                  background: s.bg,
                  color: s.color,
                  border: s.border,
                }}
                className="agendaList"
              >
                <Row>
                  <Col md={3} className="py-3 mb-1 text-right timeShowCont">
                    <h3 className="text-center d-inline-block timeShow">
                      {s.time}
                      {s.timeSub && (
                        <small className="d-block">{s.timeSub}</small>
                      )}
                    </h3>
                  </Col>
                  <Col md={9} className="py-3 mb-1 text-left contentShow">
                    <h3>{s.content}</h3>
                    {s.bullets && s.bullets.length > 0 && (
                      <ul className="">
                        {s.bullets.map((s, i) => (
                          <li key={i}>{s}</li>
                        ))}
                      </ul>
                    )}
                  </Col>
                </Row>
              </div>
            ))}
          </div>
          <Row>
            <Col lg={12} className="py-3 mb-5 text-center">
              <Button
                href="/riyadh/register"
                className="navbar-item-custom text-400 text-dark rounded-0 "
                style={{
                  backgroundImage:
                    "linear-gradient(315deg, #639ccc 0%, #000000 96%)",
                  border: "2px solid #fff",
                }}
                size="lg"
              >
                <span
                  style={{
                    color: "#fff",
                    textTransform: "none",
                    fontWeight: 700,
                    fontSize: "16px",
                  }}
                  className="text-600"
                >
                  REGISTER NOW <i class="fa fa-arrow-right" />
                </span>
              </Button>
            </Col>
            {/* <Col lg={12} className="pt-3 text-center"></Col> */}
          </Row>
        </Container>
      </div>
    </>
  );
}

export default Agenda;
