import React, { useState } from "react";

import { Container, Row, Col } from "reactstrap";
// import { Gallery } from "react-grid-gallery";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";

import Slider from "react-slick";

function News({ news }) {
  const [index, setIndex] = useState(-1);

  const currentImage = images[index];
  const nextIndex = (index + 1) % images.length;
  const nextImage = images[nextIndex] || currentImage;
  const prevIndex = (index + images.length - 1) % images.length;
  const prevImage = images[prevIndex] || currentImage;

  const handleClick = (index, item) => setIndex(index);
  const handleClose = () => setIndex(-1);
  const handleMovePrev = () => setIndex(prevIndex);
  const handleMoveNext = () => setIndex(nextIndex);

  const settings = {
    dots: true,
    autoplay: true,
    arrows: false,
    autoplaySpeed: 8000,
    slidesToShow: 3,
    slidesToScroll: 1,
    className: "p-10",
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  console.log(currentImage);
  return (
    <>
      <div
        style={{
          backgroundColor: "#fff",
        }}
        className="section section-with-space"
        data-parallax={true}
      >
        <div className="overlay2 d-none d-xl-block d-lg-block " />
        <div className="overlayMedium d-none d-xl-none d-lg-none d-md-block" />
        <div className="overlayMobile d-xl-none d-lg-none d-md-none d-sm-block d-xs-block" />
        <Container>
          <Row>
            <Col xs={12}>
              <div className="title">
                <h1 className="text-center section-title text-main text-uppercase">
                  <b>A GLIMPSE FROM THE 2023 UAE EVENT</b>
                </h1>
              </div>
            </Col>
          </Row>
          <Row>
            <Col lg={12} className="justify-content-center">
              <div class="embed-responsive embed-responsive-16by9">
                <iframe
                  title="bit video"
                  class="embed-responsive-item"
                  src="https://www.youtube.com/embed/jimWYhXPSwk?rel=0"
                  allowfullscreen
                ></iframe>
              </div>
              {images.map((n) => (
                <img src={`assets/photos/${n.image}`} key={n.image} alt="/" onClick={handleClick} />
              ))}
              {/* <Gallery
                images={images.map((n) => ({
                  src: require(`assets/photos/${n.image}`),
                  // height: 340,
                  width: 500,
                }))}
                onClick={handleClick}
                enableImageSelection={false}
                maxRows={50}
                margin={8}
              /> */}
            </Col>
            {/* {images.map((n, index) => (
              <Col lg={4} xs={6} className={"ml-auto mr-auto my-2"}>
                <img
                  src={require(`assets/photos/${n.image}`)}
                  style={{ maxHeight: 300 }}
                  alt="event"
                  className="px-1"
                />
              </Col>
            ))} */}
            {!!currentImage && (
              /* @ts-ignore */
              <Lightbox
                mainSrc={require(`assets/photos/${currentImage.image}`)}
                imageTitle={currentImage.image}
                mainSrcThumbnail={require(`assets/photos/${currentImage.image}`)}
                nextSrc={require(`assets/photos/${nextImage.image}`)}
                nextSrcThumbnail={require(`assets/photos/${nextImage.image}`)}
                prevSrc={require(`assets/photos/${prevImage.image}`)}
                prevSrcThumbnail={require(`assets/photos/${prevImage.image}`)}
                onCloseRequest={handleClose}
                onMovePrevRequest={handleMovePrev}
                onMoveNextRequest={handleMoveNext}
              />
            )}
          </Row>
        </Container>
      </div>
    </>
  );
}

export default News;

const images = [
  { image: "IMG_0215.jpg" },
  { image: "IMG_0393.jpg" },
  { image: "IMG_0368.jpg" },
  { image: "IMG_0354.jpg" },
  { image: "IMG_0584.jpg" },
  { image: "IMG_1007.jpg" },
  { image: "IMG_0318.jpg" },
  { image: "IMG_0328.jpg" },
  { image: "IMG_0334.jpg" },
  { image: "IMG_0579.jpg" },
  { image: "IMG_0702.jpg" },
  { image: "IMG_0653.jpg" },
  { image: "IMG_0658.jpg" },
  { image: "IMG_0851.jpg" },
  { image: "IMG_0921.jpg" },
  { image: "IMG_0925.jpg" },
  { image: "IMG_0943.jpg" },
  { image: "IMG_1025.jpg" },
  { image: "IMG_1049.jpg" },
  { image: "IMG_1057.jpg" },
  { image: "IMG_1075.jpg" },
  { image: "IMG_1271.jpg" },
  { image: "IMG_1281.jpg" },
  { image: "IMG_1363.jpg" },
  { image: "IMG_1430.jpg" },
  { image: "IMG_1471.jpg" },
  { image: "IMG_1497.jpg" },
  { image: "IMG_1501.jpg" },
  { image: "IMG_1544.jpg" },
  { image: "5H6A0856.jpg" },
  { image: "5H6A0872.jpg" },
  { image: "5H6A1089.jpg" },
  { image: "5H6A1172.jpg" },
  { image: "5H6A1307.jpg" },
  { image: "5H6A1365.jpg" },
  { image: "5H6A1408.jpg" },
  { image: "5H6A1425.jpg" },
  { image: "5H6A1436.jpg" },
  { image: "5H6A1464.jpg" },
  { image: "5H6A1518.jpg" },
  { image: "5H6A1581.jpg" },
  { image: "5H6A1700.jpg" },
  { image: "5H6A1710.jpg" },
  { image: "5H6A1718.jpg" },
  { image: "5H6A1749.jpg" },
  { image: "5H6A1762.jpg" },
  { image: "5H6A1831.jpg" },
  { image: "5H6A1897.jpg" },
  { image: "5H6A1931.jpg" },
  { image: "5H6A1936.jpg" },
  { image: "5H6A1951.jpg" },
  { image: "5H6A2172.jpg" },
  { image: "5H6A2242.jpg" },
  { image: "5H6A2257.jpg" },
  { image: "5H6A2325.jpg" },
  { image: "5H6A2413.jpg" },
  { image: "5H6A2416.jpg" },
  { image: "5H6A2482.jpg" },
];
