import React, { useEffect, useState } from "react";
import { Button, Container, Row, Col, Input, Form, Alert, Spinner, Label } from "reactstrap";
import { Redirect } from "react-router-dom";
// import PhoneInput from "react-phone-input-2";
// import "react-phone-input-2/lib/style.css";
// import countries from "./countries";
// import { apiURL } from "constant";
// import { token } from "constant";
// import { qatarToken } from "constant";

function Register({ type, subject }) {
  const urlParams = new URLSearchParams(window.location.search);
  const utm_source = urlParams.get("utm_source");
  let utmSource;
  if (utm_source) {
    localStorage.setItem("utmSource", utm_source);
    utmSource = utm_source;
  } else {
    utmSource = localStorage.getItem("utmSource");
  }
  const [details, setDetails] = React.useState({
    privacyPolicy: true,
    utmSource,
  });

  const checkboxChange = (e) => {
    setDetails({ ...details, [e.target.name]: e.target.checked });
  };
  const [errorObj, setErrorObj] = useState({});
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState(false);
  const [success, setSuccess] = React.useState(false);
  const [redirect, setRedirect] = React.useState(false);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const onChangeDetails = (e) => {
    setErrorObj({});
    setDetails({ ...details, [e.target.name]: e.target.value });
  };

  const validateInputs = () => {
    const reg =
      /^[a-zA-Z0-9._%+-]+@(?!gmail.com)(?!yahoo.com)(?!hotmail.com)(?!yahoo.co.in)(?!aol.com)(?!live.com)(?!outlook.com)[a-zA-Z0-9_-]+.[a-zA-Z0-9-.]{2,61}$/;

    if (!details.companyName) {
      setErrorObj({ ...errorObj, companyName: "Company Name is required." });
      return false;
    }
    if (!details.industrySector) {
      setErrorObj({ ...errorObj, industrySector: "Industry Sector is required." });
      return false;
    }
    if (!details.email || !reg.test(details.email.toLowerCase())) {
      setErrorObj({ ...errorObj, email: "Enter Valid Company Email Address." });
      return false;
    }
    if (!details.phone) {
      setErrorObj({ ...errorObj, phone: "Phone is required." });
      return false;
    }

    return true;
  };

  const onSubmitForm = (e) => {
    e.preventDefault();
    setLoading(true);
    setError(false);
    setSuccess(false);

    const validateInp = validateInputs();
    if (!validateInp) {
      setLoading(false);
      setError("Please correct one or more fields above.");
      return null;
    }

    fetch("https://asia-east2-cmoconnext.cloudfunctions.net/api/startupLounge", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(details),
    })
      .then((res) => {
        console.log(res);
        if (res.ok) return res;
        else throw new Error(res);
      })
      .then(() => {
        setLoading(false);
        setRedirect(true);
      })
      .catch((err) => {
        setLoading(false);
        setError("Something went wrong please try again");
      });
  };

  if (redirect) {
    return <Redirect to={`/qatar/thank-you-startup`} />;
  }

  const ErrorComp = ({ error }) => (
    <span
      style={{
        background: "red",
        color: "#fff",
        padding: "2px",
        marginTop: "2px",
      }}
    >
      {error}
    </span>
  );

  return (
    <div
      style={{
        backgroundColor: "#fff",

        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundAttachment: "fixed",
      }}
      className="section py-3"
    >
      <Container>
        <Row>
          <Col lg={12} className="">
            <h1 className="text-300 text-uppercase text-center text-dark">
              Fintech Innovations Lounge Program Application Form
            </h1>
          </Col>
          <Col className="mx-auto mb-5 py-5" lg={8}>
            <Container className="my-auto text-400 text-dark">
              <Form onSubmit={onSubmitForm} className="register-form">
                <Row>
                  <Col lg={12} className="">
                    <h4 className="text-300 text-uppercase text-left text-dark mb-3">
                      Company Information:
                    </h4>
                  </Col>
                  <Col lg={6} className="my-2">
                    <label>Company Name*</label>
                    <Input
                      placeholder="Company Name"
                      type="text"
                      name="companyName"
                      onChange={onChangeDetails}
                      required
                    />
                    {errorObj.companyName && <ErrorComp error={errorObj.companyName} />}
                  </Col>
                  <Col lg={6} className="my-2">
                    <label>Industry Sector*</label>
                    <Input
                      placeholder="Industry Sector"
                      type="text"
                      name="industrySector"
                      onChange={onChangeDetails}
                      required
                    />
                    {errorObj.industrySector && <ErrorComp error={errorObj.industrySector} />}
                  </Col>
                  <Col lg={6} className="my-2">
                    <label>Year Founded*</label>
                    <Input
                      placeholder="Year Founded"
                      type="text"
                      name="yearFounded"
                      onChange={onChangeDetails}
                      required
                    />
                    {errorObj.yearFounded && <ErrorComp error={errorObj.yearFounded} />}
                  </Col>

                  <Col lg={6} className="my-2">
                    <label>Headquarters Location*</label>
                    <Input
                      placeholder="Headquarters Location"
                      type="text"
                      name="headquarters"
                      onChange={onChangeDetails}
                      required
                    />
                    {errorObj.headquarters && <ErrorComp error={errorObj.headquarters} />}
                  </Col>
                  <Col lg={6} className="my-2">
                    <label>Website*</label>
                    <Input
                      placeholder="Website"
                      type="text"
                      name="website"
                      onChange={onChangeDetails}
                      required
                    />
                    {errorObj.website && <ErrorComp error={errorObj.website} />}
                  </Col>

                  <Col lg={6} className="my-2">
                    <label>Contact/SPOC*</label>
                    <Input
                      placeholder="Contact/SPOC"
                      type="text"
                      name="spoc"
                      onChange={onChangeDetails}
                      required
                    />
                    {errorObj.spoc && <ErrorComp error={errorObj.spoc} />}
                  </Col>

                  <Col lg={6} className="my-2">
                    <label>Email*</label>
                    <Input
                      placeholder="Email"
                      type="text"
                      name="email"
                      onChange={onChangeDetails}
                      required
                    />
                    {errorObj.email && <ErrorComp error={errorObj.email} />}
                  </Col>
                  <Col lg={6} className="my-2">
                    <label>Phone*</label>
                     <Input
                        placeholder="Phone*"
                        type="number"
                        name="phone"
                        onChange={onChangeDetails}
                        required
                    />
                    {/* <PhoneInput
                      country={"ae"}
                      inputProps={{
                        name: "phone",
                        required: true,
                        autoFocus: true,
                      }}
                      inputClass="w-100"
                      dropdownClass="text-white"
                      value={details.phone}
                      onChange={(phone) => {
                        setDetails({ ...details, phone });
                      }}
                    /> */}
                    {errorObj.phone && <ErrorComp error={errorObj.phone} />}
                  </Col>
                  <Col lg={12} className="my-2">
                    <label>Product/Service/Solution category Information:*</label>
                    <Input
                      placeholder="Category Information"
                      type="text"
                      name="category"
                      onChange={onChangeDetails}
                      required
                    />
                    {errorObj.category && <ErrorComp error={errorObj.category} />}
                  </Col>
                </Row>
                <Row className="mt-4">
                  <Col lg={12} className="my-2">
                    <label> Brief Description of Product/Service/Solution:*</label>
                    <Input
                      placeholder=""
                      type="textarea"
                      name="description"
                      onChange={onChangeDetails}
                      required
                    />
                    {errorObj.description && <ErrorComp error={errorObj.description} />}
                  </Col>
                  <Col lg={12} className="my-2">
                    <label>Current Target Market:*</label>
                    <Input
                      placeholder="Target Market"
                      type="text"
                      name="targetMarket"
                      onChange={onChangeDetails}
                      required
                    />
                    {errorObj.targetMarket && <ErrorComp error={errorObj.targetMarket} />}
                  </Col>
                  <Col lg={12} className="my-2">
                    <label>Unique Selling Proposition (USP):*</label>
                    <Input
                      placeholder="USP"
                      type="text"
                      name="usp"
                      onChange={onChangeDetails}
                      required
                    />
                    {errorObj.usp && <ErrorComp error={errorObj.usp} />}
                  </Col>
                  <Col lg={12} className="my-2">
                    <label>
                      Current Stage of Development (Concept, Prototype, MVP, Fully Developed,
                      etc.):*
                    </label>
                    <Input
                      placeholder="Stage"
                      type="text"
                      name="stage"
                      onChange={onChangeDetails}
                      required
                    />
                    {errorObj.stage && <ErrorComp error={errorObj.stage} />}
                  </Col>
                  <Col lg={12} className="my-2">
                    <label>Key Features/Benefits/Solution to challenge:*</label>
                    <Input
                      placeholder=""
                      type="textarea"
                      name="features"
                      onChange={onChangeDetails}
                      required
                    />
                    {errorObj.features && <ErrorComp error={errorObj.features} />}
                  </Col>
                </Row>
                <Row>
                  <Col lg={12} className="my-2">
                    <label>Number of Team Members:*</label>
                    <Input
                      placeholder=""
                      type="text"
                      name="teamCount"
                      onChange={onChangeDetails}
                      required
                    />
                    {errorObj.teamCount && <ErrorComp error={errorObj.teamCount} />}
                  </Col>
                  <Col lg={12} className="my-2">
                    <label>Founders/Key Team Members:*</label>
                    <Input
                      placeholder=""
                      type="text"
                      name="teamMembers"
                      onChange={onChangeDetails}
                      required
                    />
                    {errorObj.teamMembers && <ErrorComp error={errorObj.teamMembers} />}
                  </Col>
                  <Col lg={12} className="my-2">
                    <label>Name(s):*</label>
                    <Input
                      placeholder=""
                      type="textarea"
                      name="teamMemberNames"
                      onChange={onChangeDetails}
                      required
                    />
                    {errorObj.teamMemberNames && <ErrorComp error={errorObj.teamMemberNames} />}
                  </Col>
                  <Col lg={12} className="my-2">
                    <label>Position:*</label>
                    <Input
                      placeholder=""
                      type="text"
                      name="position"
                      onChange={onChangeDetails}
                      required
                    />
                    {errorObj.position && <ErrorComp error={errorObj.position} />}
                  </Col>
                  <Col lg={12} className="my-2">
                    <label>LinkedIn Profile (if available):</label>
                    <Input placeholder="" type="text" name="linkedIn" onChange={onChangeDetails} />
                    {errorObj.linkedIn && <ErrorComp error={errorObj.linkedIn} />}
                  </Col>
                </Row>
                <Row>
                  <Col lg={12} className="my-2">
                    <label>Current Funding Status:*</label>
                    <Input
                      placeholder=""
                      type="text"
                      name="fundingStatus"
                      onChange={onChangeDetails}
                      required
                    />
                    {errorObj.fundingStatus && <ErrorComp error={errorObj.fundingStatus} />}
                  </Col>
                  <Col lg={12} className="my-2">
                    <label>Revenue (if applicable):</label>
                    <Input placeholder="" type="text" name="revenue" onChange={onChangeDetails} />
                    {errorObj.revenue && <ErrorComp error={errorObj.revenue} />}
                  </Col>
                  <Col lg={12} className="my-2">
                    <label>Funding Round (if applicable):</label>
                    <Input
                      placeholder=""
                      type="text"
                      name="fundingRound"
                      onChange={onChangeDetails}
                    />
                    {errorObj.fundingRound && <ErrorComp error={errorObj.fundingRound} />}
                  </Col>
                  <Col lg={12} className="my-2">
                    <label>Who were you referred by:</label>
                    <Input
                      placeholder=""
                      type="text"
                      name="referredBy"
                      onChange={onChangeDetails}
                    />
                    {errorObj.referredBy && <ErrorComp error={errorObj.referredBy} />}
                  </Col>
                  <Col lg={12} className="my-2">
                    <label>Additional Information::</label>
                    <Input
                      placeholder=""
                      type="text"
                      name="additionalInfo"
                      onChange={onChangeDetails}
                    />
                    {errorObj.additionalInfo && <ErrorComp error={errorObj.additionalInfo} />}
                  </Col>
                </Row>
                <Row>
                  <Col lg={12} className="my-2">
                    <label>
                      What makes your startup a good fit for the Fintech Innovations Lounge Program?
                      Including what is your proposition for the Qatar / GCC market(Max 200 words):*
                    </label>
                    <Input
                      placeholder=""
                      type="textarea"
                      name="goodFit"
                      onChange={onChangeDetails}
                      required
                      maxLength="200"
                    />
                    {errorObj.goodFit && <ErrorComp error={errorObj.goodFit} />}
                  </Col>
                </Row>
                <Row>
                  <Col lg={12} className="my-2">
                    <div className="ml-4">
                      <Label check>
                        <Input
                          type="checkbox"
                          name="privacyPolicy"
                          onChange={checkboxChange}
                          checked={details.privacyPolicy}
                        />
                        By ticking this box, I authorize Khaleej Times to utilize the information I
                        have provided in this form for the purpose of communication. I understand
                        that my personal data will be handled in accordance with the{" "}
                        <a
                          href="https://www.khaleejtimes.com/privacy-policy"
                          className="text-dark text-bold"
                        >
                          privacy policy,{" "}
                        </a>
                        of Khaleej Times.
                      </Label>
                    </div>
                  </Col>
                </Row>

                {loading ? (
                  <span>
                    <Spinner className="mt-3" color="warning" size="sm" />
                  </span>
                ) : (
                  <Button
                    type="submit"
                    disabled={loading || !details.privacyPolicy}
                    className="text-600 px-4 w-100"
                    color="primary"
                    size="lg"
                    style={{
                      backgroundImage: "linear-gradient(315deg, #639ccc 0%, #000000 96%)",
                      border: "2px solid #000",
                    }}
                  >
                    <span
                      style={{
                        fontWeight: 700,
                        color: "#fff",
                      }}
                    >
                      SUBMIT
                    </span>
                  </Button>
                )}
              </Form>
            </Container>
            <CreateAlert success={success} error={error} type={type} />
          </Col>
        </Row>
      </Container>
    </div>
  );
}

function CreateAlert({ success, error, type }) {
  const color = success ? "success" : "danger";
  let t = "";

  if (type === "AGENDA") {
    t = "agenda";
  }
  if (!success && !error) {
    return "";
  } else {
    return (
      <Alert
        color={color}
        isOpen={true}
        fade={true}
        className="text-center"
        style={{ marginTop: "1rem" }}
      >
        {error ? (
          error
        ) : (
          <>
            <a href={success} target="_blank" rel="noopener noreferrer" className="text-white">
              Click here
            </a>{" "}
            if your download does not begin.
          </>
        )}
      </Alert>
    );
  }
}

export default Register;
