import React from "react";
import Navbar from "componentsRiyadh/Navbar.js";
import ProfilePageHeader from "componentsRiyadh/Headers/ProfilePageHeader.js";
// G:\rahulitjec\KTAllEventsWebsites\bit\website\src\componentsRiyadh
import Footer from "componentsRiyadh/Footer.js";

import ContactUs from "componentsRiyadh/ContactUs";
// import SponsorNew from "componentsQatar/SponsorNew.js";
// import { sponsorsPage } from "./content";

function RegisterPage() {
  return (
    <>
      <Navbar />
      <ProfilePageHeader title={"Contact Us"} bgColor="#fff" />
      <ContactUs />
      {/* <SponsorNew sponsors={sponsorsPage} /> */}
      <Footer />
    </>
  );
}

export default RegisterPage;
